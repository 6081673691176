.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-content {
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
    width: 900.61px;
}

.modal-content-resized {
    width: 100vw !important;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.icon-x-container {
    position: relative;
    width: 100%;
    height: 1.2em;
}

.booking-system-icon-x {
    cursor: pointer;
    height: 1.5em;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #7F7F7F;
}

.booking-system-body-container {
    height: 475px;
    overflow-y: scroll;
}

.horizontal-view-1 {
    height: 375px;
}

.horizontal-view-2 {
    height: 275px;
}

.horizontal-view-3 {
    height: 155px;
}

.booking-system-body-container-inner {
    gap: 100px;
    padding: 50px;
}

.booking-system-body-container-inner-resized {
    padding: 25px !important;
}

.steps-container {
    gap: 20px;
}

.steps-text {
    font-weight: bold;
    color: #6d6d6d;
    width: max-content;
}

.current-step {
    color: black !important;
    text-decoration: underline;
}

.title-padding {
    padding-left: 20px;
}

.title-bar-container {
    padding-top: 15px;
}

/* SELECT SERVICE STYLING */
.select-service-container {
    gap: 20px;
    width: 100%;
}

.select-service-checkbox-container {
    border: 1px solid gainsboro;
    border-radius: 5px;
    padding: 20px;
    gap: 15px;
    cursor: pointer;
}

.select-service-checkbox-container:hover {
    border: 1px solid black;
}

.select-service-checkbox-container-checked {
    border: 1px solid black;
}

.select-service-title-container {
    justify-content: space-between;
}

.more-info-button {
    width: fit-content;
}

.more-info-text {
    color: #6d6d6d;
    font-size: 14px;
    line-height: 1.25em;
}

.appointment-info-container-resized {
    gap: 5px;
}

.dropdown-input {
    position: relative;
    border: 1px solid #D3D3D3;
    border-radius: 7px;
    padding-left: 15px;
    cursor: pointer;
    box-sizing: border-box;
    font-size: 16px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.dropdown-input:hover {
    -webkit-appearance: none;
    -webkit-box-shadow:inset 0 0 0 2px black;
    box-shadow: inset 0 0 0 2px black;
}

.dropdown-input:focus {
    -webkit-appearance: none;
    -webkit-box-shadow:inset 0 0 0 2px black;
    box-shadow: inset 0 0 0 2px black;
    outline: none;
}


/* ContinueBar */
.continue-bar-container {
    padding-bottom: 15px;
}

.continue-button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: black;
    color: white;
    border-radius: 10px;
    height: 45px;
    width: fit-content;
    padding-left: 15px;
    padding-right: 15px;
    float: right;
    margin-top: 5px;
    margin-right: 15px;
}

.continue-button-not-ready {
    background-color: #c8c8c8 !important;
    cursor: default !important;
}

.continue-button-text-not-ready {
    cursor:default;
}

.back-button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid black;
    background-color: white;
    color: black;
    border-radius: 10px;
    height: 45px;
    width: fit-content;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 5px;
    margin-left: 15px;
}

.continue-back-container {
    justify-content: space-between;
}

/* Select Date and Time */
.select-service-date-time-container {
    gap: 20px;
    width: 100%;
}

.availability-text {
    font-size: 20px;
}

.react-calendar {
    width: 100% !important;
}

.react-calendar__navigation__label {
    pointer-events: none;
}

.react-calendar__navigation button {
    background: none;
    min-width: 44px;
    color: black;
}

.react-calendar__tile {
    color: black !important;
    outline: 1px solid black !important;
}

.react-calendar__tile:hover {
    background: #72A0C1 !important;
  }
  
.react-calendar__tile--active {
background: black !important;
color: white;
}

.react-calendar__tile--active.available-day {
    background: black !important;
    color: white !important;
}

.available-day {
    background: #B9D9EB !important;
}

.unavailable-day:hover {
    background-color: #f0f0f0 !important;
  }

.react-calendar__navigation__label__labelText {
    font-weight: bold;
    font-size: 18px;
}

.availability-day-container {
    gap: 10px;
}

.availability-day-info-text {
    color: #6d6d6d;
}

.time-slots-container {
    flex-wrap: wrap;
    gap: 10px;
}

.time-slots-container-resized {
    justify-content: center;
}

.time-button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #B9D9EB;
    border-radius: 10px;
    height: 45px;
    width: 90px;
    padding-left: 15px;
    padding-right: 15px;
    float: right;
    border: 1px solid black;
}

.time-button:hover {
    background-color: #72A0C1;
  }

.selected-time-button {
    background-color: black;
    color: white;
}

.selected-time-button:hover {
    background-color: black !important;
}


/* Enter your details */
.enter-details-container {
    width: 100%;
    gap: 20px;
}

.enter-details-input {
    position: relative;
    width: 100%;
    border: 1px solid #D3D3D3;
    border-radius: 7px;
    padding-left: 15px;
    cursor: pointer;
    box-sizing: border-box;
    font-size: 16px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.enter-details-input:hover {
    -webkit-appearance: none;
    -webkit-box-shadow:inset 0 0 0 2px black;
    box-shadow: inset 0 0 0 2px black;
}

.enter-details-input:focus {
    -webkit-appearance: none;
    -webkit-box-shadow:inset 0 0 0 2px black;
    box-shadow: inset 0 0 0 2px black;
    outline: none;
}

.phone-not-complete {
    -webkit-appearance: none;
    -webkit-box-shadow:inset 0 0 0 2px red;
    box-shadow: inset 0 0 0 2px red !important;
}

.enter-details-long-text {
    line-height: 1.5;
    overflow: auto;
    resize: none;
}

.full-name-container {
    gap: 10px;
}
  
.cancellation-text {
    color: #6d6d6d;
}
