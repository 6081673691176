.AboutMe {
    position: relative;
}

.AboutMe::before {
    content: "";
    background-image: url('../../images/mandala_background.png');
    /* background-color: rgba(173,216,230);
    background-blend-mode: multiply; */
    background-size: cover;
    background-position: center;
    opacity: 0.6;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
}

.AboutMe-info {
    display: flex;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    justify-content: space-between
}

span.center {
    display: table;
    margin: 0 auto;
}

span.five-spaces {
    margin-left: 43px;
}

span.six-spaces {
    margin-left: 48px;
}

span.bold {
    font-weight: bold;
}

div.AboutMe-image-container {
    display: flex;
    justify-content: center;
    width: 40vw;
}

div.AboutMe-image-container-resized {
    display: flex;
    justify-content: center;
    width: 90vw;
}

div.AboutMe-text-container {
    width: 50vw;
    padding-right: 20px;
}

.AboutMe-title-container {
    gap: 50px;
    justify-content: center;
    align-items: center;
}

.RYT-logo {
    width: 80px;
}

/*resized for phone*/

.AboutMe-info-resize {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
}

div.AboutMe-text-container-resized {
    padding-left: 50px;
    padding-right: 50px;
}

.about-me-laura-pic {
    border-radius: 20px;
    width: 100%;
}