div.center {
    align-items: center;
}

.MTH-pictures-container {
    padding-top: 50px;
    padding-bottom: 50px;
}

.MTH-container {
    position: relative;
}

.MTH-container::before {
    content: "";
    background-image: url('../../images/mandala_background.png');
    /* background-color: rgba(173,216,230);
    background-blend-mode: multiply; */
    background-size: cover;
    background-position: center;
    opacity: 0.6;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
}

.MTH-mandala-text-container {
    padding-top: 40px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 40px;
}

.FAQ-Button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: black;
    color: white;
    border-radius: 10px;
    height: 45px;
    width: fit-content;
    padding-left: 20px;
    padding-right: 20px;
}

.FAQ-container {
    justify-content: center;
}

.pictures-container {
    gap: 50px;
}

.text-col-container {
    width: 400px;
}

.text-col-container-mobile {
    width: 81.8%;
}

.row-container {
    justify-content: center;
    gap: 50px;
}

.col-container {
    height: 300px;
    width: 100%;
    overflow: hidden;
}

.img-sizing {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}

.text-center {
    display: block;
    text-align: center;
}