/* Position and sizing of burger button */
.bm-burger-button {
    position: absolute;
    width: 36px;
    height: 30px;
    left: initial;
    top: 85px;
    right: 36px;
}
  
/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: black;
}
  
/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: gray;
}

.bm-cross-button {
    position: relative;
}

.bm-cross {
    background: black;
}

.bm-menu {
    background-color: white;
    padding: 60px 0;
    position: relative;
    overflow: hidden !important;
}

.bm-item-list {
    overflow-y: scroll;
    border-top: 2px solid black;
    border-bottom:  2px solid black;
    padding-top: 10px;
}

/* The tabs */
.bm-tab {
    border-bottom: 1px solid black;
    padding: 15px 8px;
    cursor: pointer;
    user-select: none;
}

.bm-tab:first-child {
    border-top: 1px solid black;
}
  
.bm-tab:hover {
    background-color: #d3d3d3;
}

.bm-subtab {
    border-bottom: 1px solid black;
    padding: 15px 30px;
    cursor: pointer;
    background-color: #B9D9EB;
    user-select: none;
}
  
.bm-subtab:hover {
    background-color: #d3d3d3;
}


.bm-subtab-wrapper {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.6s ease-out;
  }
  
.bm-subtab-wrapper.open {
    max-height: 500px; 
}

.icon-arrow {
cursor: pointer;
height: 1.0em;
position: absolute;
right: 10px;
top: 50%;
transform: translateY(-50%);
}

.book-container {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 20px;
}