div.NavBar {
    display: flex;
    align-items: center;
    height: 200px;
    justify-content: center;
    gap: 50px;
}

img.laura-pic {
    height: 150px;
    cursor: pointer;
    border-radius: 20px;
}

div.tabs-container {
    display: flex;
    gap: 30px;
    align-items: center;
}

div.tab-container {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 45px;
    width: fit-content;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
    margin-bottom: 5px;
    font-size: 14px;
}

div.tab-container::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 2px;
    background-color: black;
    transition: width 0.3s ease-in-out; /* animate the width change */
}

div.tab-container:hover::before {
    width: 100%; /* animate the width to 100% on hover */
}

.tab-container:hover ~ .menu {
    display: flex;
}

div.book {
    display: flex !important;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: black;
    color: white;
    border-radius: 10px;
    height: 45px;
    width: fit-content;
    padding-left: 15px;
    padding-right: 15px;
}


div.resized {
    justify-content: normal !important;
}

img.resized {
    margin-left: 36px;
}

.dropdown-menu-container {
    display: inline-block;
    flex-direction: column;
    gap: 5px;
    position: 'relative';
}

.menu {
    position: absolute;
    z-index: 100;
    width: 200px;
    border: 1px solid grey;
    background-color: white;
    display: flex;
    flex-direction: column;
}

.menu-item {
    line-height: 45px;
    padding-left: 15px;
    cursor: pointer;
}

.menu-item:hover {
    background-color: #d8d8d8;
}