.img-size {
    width: 300px;
    height: auto;
    object-fit: cover;
}

.video-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding-top: 50px;
    padding-bottom: 50px;
}

.video-responsive {
    position: relative;
    width: 70%;
    height: 50vw;
    cursor: pointer;
}
  
.video-responsive iframe {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}

.video-overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 1;
    background-color: transparent;
  }

.width-text {
  max-width: 90%;
}