.events-request-container {
    position: relative;
    padding-top: 50px;
    padding-bottom: 50px;
	padding-left: 10%;
	padding-right: 10%;
}

.events-request-container::before {
    content: "";
    background-image: url('../../images/mandala_background.png');
    background-size: cover;
    background-position: center;
    opacity: 0.5;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
}

.events-inputs-container {
    gap: 20px;
}

.events-request-input {
    position: relative;
    width: 700px;
    border: 1px solid #D3D3D3;
    border-radius: 7px;
    padding-left: 15px;
    cursor: pointer;
    box-sizing: border-box;
    font-size: 16px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.events-request-input-resize {
    width: 90vw !important;
}

.events-request-input:hover {
    -webkit-appearance: none;
    -webkit-box-shadow:inset 0 0 0 2px black;
    box-shadow: inset 0 0 0 2px black;
}

.events-request-input:focus {
    -webkit-appearance: none;
    -webkit-box-shadow:inset 0 0 0 2px black;
    box-shadow: inset 0 0 0 2px black;
    outline: none;
}

.events-not-complete {
    -webkit-appearance: none;
    -webkit-box-shadow:inset 0 0 0 2px red;
    box-shadow: inset 0 0 0 2px red !important;
}

.time-container {
    width: 700px;
    height: 100%;
    gap: 10px;
}

.time-container-resize {
    width: 90vw !important;
}

.time-element {
    width: 100% !important;
}

.submit-container {
    display: flex;
    justify-content: center;
    width: 100%;
}

.submit-button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: black;
    color: white;
    border-radius: 10px;
    height: 45px;
    width: fit-content;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 5px;
    margin-left: 15px;
}

/* 
DatePicker
 */

.react-datepicker__triangle {
    display: none;
  }

.react-datepicker__input-container {
    height: 100%;
}

.react-datepicker-wrapper input::placeholder {
    color: #7F7F7F;
}

.datepicker-container {
    position: relative;
    width: 95%;
    justify-content: flex-start;
}

.datepicker-options {
    width: 100% !important;
}

/* 
Time Input
 */

 .select-container {
    position: relative;
    width: 95%;
    justify-content: flex-end;
 }

.placeholder-color {
    color: #7F7F7F;
}

.select-color {
    color: black;
}

.select-options {
    background-color: white;
    appearance: none;
}

.icon-x {
    cursor: pointer;
    height: 1.2em;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #7F7F7F;
}

/* Event type input */
.event-type {
    line-height: 1.5;
    overflow: auto;
    resize: none;
}

/* Location Search Input */
.location-search-input {
    position: relative;
}
