.couples-container {
    position: relative;
    padding-top: 50px;
    padding-bottom: 50px;
	padding-left: 10%;
	padding-right: 10%;
    gap: 50px;
}

.couples-container::before {
    content: "";
    background-image: url('../../images/mandala_background.png');
    /* background-color: rgba(173,216,230);
    background-blend-mode: multiply; */
    background-size: cover;
    background-position: center;
    opacity: 0.6;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
}

.couples-container-resize {
    padding-left: 5% !important;
    padding-right: 5% !important;
}

.studio-img {
    width: 60%;
    margin: auto;
    object-fit: contain;
}

div.couples-book {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: black;
    color: white;
    border-radius: 10px;
    height: 45px;
    width: fit-content;
    padding-left: 15px;
    padding-right: 15px;
}

.studio-img-resize {
    width: 90%;
    margin: auto;
    object-fit: contain;
}