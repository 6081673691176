.footer-container {
    border-top: 1px solid #D3D3D3;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    justify-content: space-around;
}

.footer-container-resize {
    border-top: 1px solid #D3D3D3;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    align-items: center;
    gap: 20px;
}

.social-icon-container {
    gap: 10px;
}

.footer-icon {
    height: 1.5em;
}

.email-icon {
    cursor: pointer;
}

.facebook-icon {
    color: #3b5998;
    cursor: pointer;
}

.instagram-icon {
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%); 
    background: -webkit-radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
    color: white;
    border-radius: 3px;
    width: 25px; 
    cursor: pointer;
}

.linkedin-icon {
    color: #0077b5;
    cursor: pointer;
}

.visa-icon {
    color: #1434CB;
}

.mastercard-icon {
    color: #FF5F00;
}

.venmo-icon {
    height: 25px;
}