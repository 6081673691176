.Map {
    height:60%;
    width:40%;
}

.Map-resized {
    height:60%; 
    width:80%;
}

.Map-resized-small {
    height: 415px;
    width:80%;
}