div.mandala-container {
    position: relative;
    width: 100%;
}

.mandala-container::before {
    content: "";
    background-image: url('../../images/mandala_background.png');
    /* background-color: rgba(173,216,230);
    background-blend-mode: multiply; */
    background-size: cover;
    background-position: center;
    opacity: 0.6;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
}

div.welcome-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-left: 15%;
    padding-top: 150px;
    padding-bottom: 150px;
}

.welcome-container-resized {
    padding-left: 0% !important;
    align-items: center;
}


div.welcome-text-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 300px;
}

span.recoleta {
    font-family: 'Recoleta-Mama';
    font-weight: bold;
}

span.big {
    font-size: 53px;
}

span.medium {
    font-size: 28px;
}

span.small {
    font-size: 16px;
}

div.location-hours-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

div.location-hours-text-container {
    gap: 50px;
}

div.location-container {
    gap: 3px;
}

div.hours-container {
    gap: 3px;
}

div.book {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: black;
    color: white;
    border-radius: 10px;
    height: 45px;
    width: fit-content;
    padding-left: 15px;
    padding-right: 15px;
}

div.location-map-container {
    display: flex;
    justify-content: center !important;
    align-items: center;
    height: 800px;
    width: 100%;
    background-color: #e8e8e8;
    gap: 5%;
}

div.location-map-resized {
    height: 850px;
}

div.email-container {
    justify-content: center;
    align-items: center;
    gap: 25px;
    height: 500px;
    width: 100%;
}

input.email-input {
    width: 500px;
    border: 1px solid #D3D3D3;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    padding-left: 15px;
    cursor: pointer;
    box-sizing: border-box;
    font-size: 16px;
    padding-top: 17px;
    padding-bottom: 17px;
}

input.email-input-resize {
    width: 70vw !important;
}

.email-input:hover {
    -webkit-appearance: none;
    -webkit-box-shadow:inset 0 0 0 2px black;
    box-shadow: inset 0 0 0 2px black;
    border-radius: 7px;
}

.email-input:focus {
    -webkit-appearance: none;
    -webkit-box-shadow:inset 0 0 0 2px black;
    box-shadow: inset 0 0 0 2px black;
    border-radius: 7px;
    outline: none;
}

.email-not-complete {
    -webkit-appearance: none;
    -webkit-box-shadow:inset 0 0 0 2px red;
    box-shadow: inset 0 0 0 2px red;
    border-radius: 7px;
}

div.sign-up {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    background-color: black;
    color: white;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    cursor: pointer;
}
