.yoga-home-img-size {
    width: 45%;
    height: auto;
    object-fit: cover;
}

.yoga-home-img-resize {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.yoga-home-container {
    position: relative;
    padding-top: 50px;
    padding-bottom: 50px;
	padding-left: 10%;
	padding-right: 10%;
    gap: 50px;
}

.yoga-home-container::before {
    content: "";
    background-image: url('../../images/mandala_background.png');
    /* background-color: rgba(173,216,230);
    background-blend-mode: multiply; */
    background-size: cover;
    background-position: center;
    opacity: 0.6;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
}

.yoga-home-container-resize {
    background-image: url('../../images/mandala_background.png');
    background-size: cover;
    background-position: center;
    padding-top: 50px;
    padding-bottom: 50px;
	padding-left: 5%;
	padding-right: 5%;
    gap: 50px;
}

.yoga-home-text-width {
    max-width: 50%;
}

.yoga-private-img-size {
    width: 50%;
    height: auto;
    object-fit: cover;
}

.yoga-thai-image-container-size {
    width: 80%;
}

.yoga-thai-image-size {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.yoga-group-buttons-container {
    gap: 20px;
}