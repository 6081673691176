.yogassage-title-container {
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.yogassage-subtitle {
    font-size: 18px;
}

.yogassage-img-container {
    height: 250px;
    width: 100%;
    overflow: hidden;
}

.yogassage-text-col-container {
    width: 250px;
}

.yogassage-pictures-container {
    display: flex;
    justify-content: center;
    gap: 50px;
    padding-bottom: 50px;
}

.yogassage-pictures-resize-container {
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.yogassage-description-container {
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 10%;
    padding-right: 10%;
}

.yogassage-mandala-container {
    position: relative;
    width: 100%;
}

.yogassage-mandala-container::before {
    content: "";
    background-image: url('../../images/mandala_background.png');
    /* background-color: rgba(173,216,230);
    background-blend-mode: multiply; */
    background-size: cover;
    background-position: center;
    opacity: 0.6;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
}

.yogassage-description-title {
    font-size: 22px;
}

.list-item {
    display: block;
    position: relative;
    padding-left: 20px;
  }
  
  .list-item::before {
    content: "\2022"; /* This is the bullet point symbol */
    position: absolute;
    left: 10px; /* This is the negative margin that pulls the bullet point to the left */
    font-weight: bold;
  }

.yogassage-text {
    font-size: 14px;
    line-height: 1.5em;
}