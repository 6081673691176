@font-face {
    font-family: 'Recoleta-Mama';
    src: url('./fonts/recoleta-regulardemo-webfont.woff2') format('woff2'),
         url('./fonts/recoleta-regulardemo-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

textarea, input {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
 }

.form-control::-moz-placeholder{
    color:#999;
    opacity:1
}

.form-control:-ms-input-placeholder{
    color:#999
}

.form-control::-webkit-input-placeholder{
    color:#999
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.align-items-center {
    align-items: center;
}

.justify-content-center {
    justify-content: center;
}