.container {
	position: relative;
    padding-top: 50px;
    padding-bottom: 50px;
	padding-left: 15%;
	padding-right: 15%;
	gap: 50px;
}

.container::before {
    content: "";
    background-image: url('../../images/mandala_background.png');
    /* background-color: rgba(173,216,230);
    background-blend-mode: multiply; */
    background-size: cover;
    background-position: center;
    opacity: 0.6;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
}

.pictures-container {
    justify-content: center;
}

.img-resize {
    width: 80%;
}

.fade-outer {
    position: relative;
}

.fade-inner {
    position: absolute;
}

.top {
	animation-name: fade;
	animation-timing-function: ease-in-out;
	animation-iteration-count: infinite;
	animation-duration: 4s;
	animation-direction: alternate;
}

@keyframes fade {
	0% {
		opacity: 1;
	}
	25% {
		opacity: 1;
	}
	75% {
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}

.rounded-borders {
	border-radius: 20px;
}

.line-height {
	line-height: 2em;
}

.trad-massage-img {
	width: 400px;
	height: 600px;
}

.trad-massage-img-mobile {
	width: 95%;
	height: auto;
}
