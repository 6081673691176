div.studio-mandala-container {
    position: relative;
    width: 100%;
    gap: 75px;
    padding-top: 50px;
    padding-bottom: 50px;
}

.studio-mandala-container::before {
    content: "";
    background-image: url('../../images/mandala_background.png');
    /* background-color: rgba(173,216,230);
    background-blend-mode: multiply; */
    background-size: cover;
    background-position: center;
    opacity: 0.6;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
}

.text-container {
    gap: 15px;
}

.instructions-container {
    justify-content: center;
    gap: 50px;
}

.instructions-resize {
    gap: 15% !important;
}

.instructions-mobile {
    gap: 50px;
}

.picture-container {
    width: 300px;
    gap: 20px;
}

.picture-inside-container {
    width: 400px;
    gap: 20px;
}

.picture-inside-mobile-container {
    width: 95%;
    align-items: center;
    gap: 20px;
}

.instructions-size {
    font-size: 18px;
}

.studio-outside-sizing {
    height: 300px;
    width: 100%;
    object-fit: cover;
    border-radius: 20px;
}

.studio-inside-sizing {
    height: 400px;
    width: 100%;
    object-fit: cover;
    border-radius: 20px;
}

.studio-mobile-sizing {
    width: 90vw;
    height: 90vw;
    object-fit: cover;
    border-radius: 20px;
}

.object-position-center-bottom {
    object-position: center bottom;
}

.subtitle-padding {
    padding-left: 5px;
    padding-right: 5px;
}

