div.FAQ-mandala-container {
    position: relative;
    gap: 30px;
    padding-top: 50px;
    padding-left: 10%;
    padding-bottom: 50px;
}

.FAQ-mandala-container::before {
    content: "";
    background-image: url('../../images/mandala_background.png');
    /* background-color: rgba(173,216,230);
    background-blend-mode: multiply; */
    background-size: cover;
    background-position: center;
    opacity: 0.6;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
}

.FAQ-mandala-container-resized {
    padding-left: 0% !important;
    align-items: center;
}


.FAQ-text-container {
    gap: 5px;
    width: 60%;
}

.FAQ-text-container-resized {
    gap: 5px;
    width: 80%;
}

.text-break {
    word-break: keep-all;
    white-space: pre-wrap;
    padding-left: 5px;
    padding-right: 5px;
}